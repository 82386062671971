import { MenuItem } from "react-pro-sidebar";


export default function YBMenuItem({ icon, name, mouseOverDescription, selected, setSelected, link }) {


    return (
<       MenuItem  icon={icon} component={link} selected={selected}  setSelected={setSelected} title={mouseOverDescription}>{name}</MenuItem>
    );

}


