import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { formatDollars, formatPercent } from "../global/utils";

function GroupMetricTable({ columns, rows, abs, ...props }) {

  // Prepare the columns with the required format for DataGrid
  const dataGridColumns = columns.map((col, index) => ({
    field: col,
    headerName: col.toUpperCase(),
    width: 200,
    valueFormatter: (params) => {
      if (params.value == null) {
        return "";
      }else if(abs && !isNaN(params.value) && index == 1){
        return `${formatDollars(params.value)}`;
      }else if (!abs && !isNaN(params.value) && index == 1) {
        return `${formatPercent(params.value)}`;
      }
      return params.value;
    },
    // Add valueGetter to return the original numeric value for sorting purposes
    valueGetter: (params) => {
      let number = Number(params.value);
      if (isNaN(number)) {
        return params.value;
      }
      return number;
    },
  }));

  // Prepare the rows with the required format for DataGrid
  const dataGridRows = rows.map((row, index) => ({
    id: index,
    [columns[0]]: row[0],
    [columns[1]]: row[1],
  }));

  // Debug: Log processed columns and rows


  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={dataGridRows}
        columns={dataGridColumns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        {...props}
      />
    </div>
  );
}

export default GroupMetricTable;
