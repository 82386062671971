import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import apiService from "../../services/apiService";
import TotalsTable from "./TotalsTable";
import { formatDollars } from "../global/utils";
import { REPORT_META } from "../../constants";
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Box,
} from "@mui/material";
import YBDatePicker  from "../../components/YBDatePicker";
import dayjs from "dayjs";


const SalesByProductPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [totals, setTotals] = useState({});

  // Auth
  const { authToken, logout } = useAuth();

  // Filters
  const [searchSku, setSearchSku] = useState("");
  const [searchDateFrom, setSearchDateFrom] = useState(dayjs().startOf('month'));
  const [searchDateTo, setSearchDateTo] = useState(dayjs());
  const [ordering, setOrdering] = useState("revenue");

  // Pagination options
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 20,
  });

  function formatDate(dateObj) {
    if (!dateObj) {
      return "";
    }

    // Extract the actual Date object from the dateObj
    const actualDate = dateObj.$d;

    // Use the Date object to get the UTC year, month, date, etc.
    const year = actualDate.getUTCFullYear();
    const month = actualDate.getUTCMonth() + 1; // getUTCMonth() returns months from 0-11
    const day = actualDate.getUTCDate();

    // Pad single digits with leading zeros
    const pad = (num) => num.toString().padStart(2, "0");

    // Construct the formatted string
    return `${year}-${pad(month)}-${pad(day)}`;
  }

  async function fetchData(url) {
    setLoading(true);
    try {
      const response = await apiService.get(url, authToken);
      if (response.results) {
        setTableData(response.results);
        setRowCount(response.count);
        setTotals({
          totalRevenue: formatDollars(response.total_revenue),
          totalRefunded: formatDollars(response.total_refunded),
          totalGrossProfit: formatDollars(response.total_gross_profit),
        });
      }
    } catch (error) {
      if (error.message === "Unauthorized access") {
        logout(); // Calls logout from AuthContext
      }
      setError("Failed to fetch data: " + error.message);
    } finally {
      setLoading(false);
    }
  }

  function fetchExtraData(page, size) {
    let url = `${
      REPORT_META.report_sales_by_product.main_api_endpoint
    }?limit=${size}&offset=${page * size}`;
    if (searchSku) url += `&product__sku=${searchSku}`;
    if (searchDateFrom)
      url += `&date_invoiced_range_after=${formatDate(searchDateFrom)}`;
    if (searchDateTo)
      url += `&date_invoiced_range_before=${formatDate(searchDateTo)}`;
    if (ordering) url += `&ordering=-${ordering}`; // TODO Make ascending/descending

    fetchData(url);
  }

  useEffect(() => {
    document.title = "Yotabase: Sales By Product";
    fetchExtraData(pagination.page, pagination.pageSize);
  }, [pagination]);

  const columns = [
    // {
    //   field: "u_id",
    //   headerName: "unique",
    // },
    // {
    //   field: "inventory_id",
    //   headerName: "Neto ID",
    // },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "date_invoiced",
      headerName: "Date Invoiced",
      flex: 1,
    },
    {
      field: "revenue",
      type: "number",
      headerName: "Revenue",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${formatDollars(params.value.toLocaleString())}`;
      },
      flex: 1,
    },
    {
      field: "order_count",
      headerName: "Order Count",
      type: "number",
      flex: 1,
    },
    {
      field: "total_sold",
      headerName: "Total Sold",
      type: "number",
      flex: 1,
    },
    {
      field: "total_refunded",
      headerName: "Total Refunded",
      type: "number",
      flex: 1,
    },
    {
      field: "value_refunded",
      headerName: "Value Refunded",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${formatDollars(params.value.toLocaleString())}`;
      },
      flex: 1,
    },
    {
      field: "cogs",
      headerName: "COGs",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${formatDollars(params.value.toLocaleString())}`;
      },
      flex: 1,
    },
    {
      field: "gross_profit",
      headerName: "Gross Profit",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${formatDollars(params.value.toLocaleString())}`;
      },
      flex: 1,
    },
    {
      field: "margin_percent",
      headerName: "Margin",
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()}%`;
      },
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Sales By Product"
        subtitle="Welcome to the sales report. Here, you can find details on all your recent sales. The data below is grouped by SKU and date invoiced so you are able to see the sales made for any given product on any given day."
      />

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.orangeAccent[400],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.orangeAccent[400]} !important`,
          },
        }}
      >
        {error && <p>Error: {error}</p>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            fetchExtraData(0, 0);
          }}
        >
          <h1>Filters</h1>
          <FormControl fullWidth sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              {/* SKU Field */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Enter SKU"
                  variant="outlined"
                  value={searchSku}
                  onChange={(e) => setSearchSku(e.target.value)}
                />
              </Grid>

              {/* Ordering Field */}
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Ordering</InputLabel>
                  <Select
                    value={ordering}
                    onChange={(e) => setOrdering(e.target.value)}
                    label="Ordering" // Add the label prop for proper display
                  >
                    <MenuItem value="revenue">Total Revenue</MenuItem>
                    <MenuItem value="product__sku">SKU</MenuItem>
                    <MenuItem value="order_count">Order Count</MenuItem>
                    <MenuItem value="total_sold">Total Sold</MenuItem>
                    <MenuItem value="cogs">COGs</MenuItem>
                    <MenuItem value="gross_profit">Gross Profit</MenuItem>
                    <MenuItem value="margin_percent">Margin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Date Pickers */}
              <Grid item xs={12} md={6}>
                <YBDatePicker
                  label="Date From"
                  variant="outlined"
                  value={searchDateFrom || null}
                  onChange={(newValue) => setSearchDateFrom(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  className="fullwidth-datepicker"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <YBDatePicker
                  label="Date To"
                  variant="outlined"
                  value={searchDateTo || null}
                  onChange={(newValue) => setSearchDateTo(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  className="fullwidth-datepicker"
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </FormControl>

        </form>
        <TotalsTable totals={totals} />
        <DataGrid
          style={{ paddingTop: "20px" }}
          getRowId={(row) => row.u_id}
          rows={tableData}
          rowCount={rowCount}
          loading={loading}
          columns={columns}
          pageSizeOptions={[20, 50, 100]}
          paginationModel={pagination}
          paginationMode="server"
          onPaginationModelChange={setPagination}
        />
      </Box>
    </Box>
  );
};

export default SalesByProductPage;
