import React, { useState, useEffect } from 'react';

const Loading = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 1000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return <div>Loading{dots}</div>;
};

export default Loading;
