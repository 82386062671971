import React, { createContext, useState, useContext, useEffect } from 'react';
import apiService from '../services/apiService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || '');
    const [businessData, setBusinessData] = useState(null);

    useEffect(() => {
        if (authToken) {
            fetchBusinessData();
        }
    }, [authToken]);

    const login = async (username, password) => {
        const { auth_token } = await apiService.post('/api/auth/token/login/', {
            username,
            password
        });

        localStorage.setItem('authToken', auth_token);
        setAuthToken(auth_token);
    };



    const fetchBusinessData = async () => {
    
        try {
            const response = await apiService.get('/api', authToken);
            setBusinessData(response);
        } catch (error) {
            console.error('Error fetching user data: ', error);
        }
    };
    

    const logout = () => {

        apiService.post('/api/auth/token/logout/', {}, authToken);
        localStorage.removeItem('authToken');
        setAuthToken('');
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout, businessData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);