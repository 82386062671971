import React from "react";
import Grid from "@mui/material/Grid";

const TotalsTable = ({ totals }) => {
  const rows = Object.entries(totals).map(([key, value]) => ({
    name: key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase()),
    value: value,
  }));

  return (
    <Grid container spacing={1} paddingLeft={'10px'}>
      {rows.map((row, index) => (
        <Grid
          item
          key={index}
          xs={12}
          sm={6}
          md={4}
          style={{ border: "1px solid white", display: 'flex', justifyContent:'space-between',padding:'20 10px', flexFlow:'column' }}
        >
          <h1 style={{ color: "#f5a845", margin:'0' }}>{row.name.split(" ")[1]}</h1>
          <p
            style={{ textAlign: "end", fontSize: "17px", alignSelf: 'center',margin:'0' ,alignSelf:'end',paddingRight:'7px'}}
          >
            {row.value}
          </p>
        </Grid>
      ))}
    </Grid>
  );
};

export default TotalsTable;
