import React, { useState, useEffect } from "react";
import { Box, useTheme, Grid, InputLabel } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import apiService from "../../services/apiService";
import { FormControl } from "@mui/material";
import { REPORT_META } from "../../constants";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import { useMediaQuery } from "@mui/material";
import { useRef } from "react";
import YBDatePicker  from "../../components/YBDatePicker";
import {formatAPIDate, URLBuilder, stripTimeFromDate, getTodayDate} from "../global/utils";


import GroupMetricTable from "./GroupMetricTable";
import YBSelect from "./YBSelect";

import BasicPie from "./MetricPie";
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from "dayjs";

// ...

const MetricsPage = () => {
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartWidth = useRef(null);
  
  const [groupbyOptions, setGroupbyOptions] = useState([]);
  const [metricOptions, setMetricOptions] = useState([]);
  const [selectedDisplayOption, setSelectedDisplayOption] = useState("perc");
  const [selectedGroupby, setSelectedGroupby] = useState(null);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const [percTableData, setPercTableData] = useState([]);
  const [absTableData, setAbsTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { authToken, logout } = useAuth();

  const [startDate, setStartDate] = useState(stripTimeFromDate(dayjs().startOf('month')));
  const [endDate, setEndDate] = useState(stripTimeFromDate(dayjs()));

  async function fetchDataPoints() {
    const url = REPORT_META.report_metrics.main_api_endpoint + "available/";
    const response = await apiService.get(url, authToken);
    setGroupbyOptions(response.group_bys);
    setMetricOptions(response.metrics);
    setSelectedGroupby(response.group_bys[0]);
    setSelectedMetric(response.metrics[0]);
  }

  async function fetchTableData() {
    setTableLoading(true);
    let url = new URLBuilder(REPORT_META.report_metrics.main_api_endpoint + `get-metrics/${selectedMetric.id}/${selectedGroupby.id}`);
    if(startDate) url.addQueryParam('date_from', formatAPIDate(startDate));
    if(endDate) url.addQueryParam('date_to', formatAPIDate(endDate));

    let data = [];
    let response; // Declare response outside the while loop
    let next = url.toString();
  
    while (next != null) {
      response = await apiService.get(next, authToken); // Use the response variable inside the loop
      data = data.concat(response.results.results);
      next = response.next

    }
  

    setRowCount(response.count);
    setPercTableData(data.map((row) => [row[0], row[2] ]));
    setAbsTableData(data.map((row) => [row[0], row[1]]));

    setTableLoading(false);
  }
  

  useEffect(() => {

    fetchDataPoints();

  }, []);

  useEffect(() => {
    if(selectedDisplayOption === 'perc'){
      setTableData(percTableData);
    } else {
      setTableData(absTableData);
    }
  }, [selectedDisplayOption, absTableData, percTableData]);


  useEffect(() => {
    if (selectedGroupby && selectedMetric) {
      fetchTableData();
    }
  }, [selectedGroupby, selectedMetric, startDate, endDate]);




  return (
    <Box m="40px 0 0 0" height="75vh" sx={{
      "& .MuiDataGrid-root": {
        border: "none",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      "& .name-column--cell": {
        color: colors.orangeAccent[400],
      },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: colors.blueAccent[700],
        borderBottom: "none",
      },
      "& .MuiDataGrid-virtualScroller": {
        backgroundColor: colors.primary[400],
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: colors.blueAccent[700],
      },
      "& .MuiCheckbox-root": {
        color: `${colors.orangeAccent[400]} !important`,
      },
    }}>
      <Box m="20px">
        <Header
          title="Metrics Report"
          subtitle="Given a metric and an entity to group by, this report will show the metric over time."
        />
  
        {!selectedGroupby || !selectedMetric ? (
          <CircularProgress sx={{color: "white"}} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} height={360}>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <Grid display={!md ? "flex" : 'block'} gap={2}>
                      <Grid item xs={12} md={6} marginBottom={!md ? 0 : 2}>
                        <YBDatePicker
                          label="Start Date"
                          value={startDate || null}
                          onChange={(newValue) => {setStartDate(stripTimeFromDate(newValue))}}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <YBDatePicker
                          label="End Date"
                          value={endDate || null}
                          onChange={(newValue) => {setEndDate(stripTimeFromDate(newValue))}}
                        />
                      </Grid>
                    </Grid>
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
              <YBSelect
                title="Group By"
                options={groupbyOptions}
                onChange={setSelectedGroupby}
                value={selectedGroupby?.id}
              />
              <YBSelect
                title="Metric"
                options={metricOptions}
                onChange={setSelectedMetric}
                value={selectedMetric?.id}
              />
              <GroupMetricTable 
                sx={{marginTop: "20px"}} 
                columns={[selectedGroupby.label, selectedMetric.label]} 
                rows={tableData} 
                loading={tableLoading}
                rowCount={rowCount}
                abs={selectedDisplayOption === 'abs'}
              />
            </Grid>
            <Grid item xs={12} md={9} style={{ marginTop: md ? 90 : 0 }} ref={chartWidth}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box width="150px">
                  <FormControl fullWidth sx={{marginTop: "20px"}}>
                    <InputLabel>Display</InputLabel>
                    <Select
                        value={selectedDisplayOption}
                        onChange={(event) => {setSelectedDisplayOption(event.target.value)}}
                    >
                        <MenuItem value='perc'>Percentage</MenuItem>
                        <MenuItem value='abs'>Absolute</MenuItem>
                    </Select>
                </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Box><BasicPie rows={tableData} abs={selectedDisplayOption == 'abs'}/></Box>
            </Grid>

          </Grid>
        )}
      </Box>
    </Box>
  );
  
}
  

export default MetricsPage;
