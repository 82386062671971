import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Typography, useTheme } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const theme = useTheme();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            await login(username, password);
        } catch (err) {
            setError('Failed to log in');
        }
    };

    const cardStyle = {
        maxWidth: 400, 
        margin: '0 auto', 
        marginTop: '5%',
        backgroundColor: theme.palette.background.paper, // Use theme color
    };

    return (
        <Card style={cardStyle}>
            <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                    Login
                </Typography>

                {/* Error Message Display */}
                {error && (
                    <Typography color="error" style={{ marginBottom: '20px' }}>
                        {error}
                    </Typography>
                )}
                
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        fullWidth
                        margin="normal"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth
                        style={{ marginTop: '20px' }}
                    >
                        Login
                    </Button>
                </form>
            </CardContent>
        </Card>
    );
};

export default LoginForm;
