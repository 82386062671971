import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);


function formatDollars(amount){
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }).format(amount);
}

function formatPercent(amount){
  const decimalAmount = amount / 100;
  return new Intl.NumberFormat('en-AU', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(decimalAmount);
}


function appendQueryParamsToRelativeUrl(relativeUrl, params) {
  let query = [];
  for (let key in params) {
      if (params.hasOwnProperty(key)) {
          query.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
      }
  }

  if (relativeUrl.includes('?')) {
      return relativeUrl + '&' + query.join('&');
  } else {
      return relativeUrl + '?' + query.join('&');
  }
}

function URLBuilder(baseUrl) {
    this.baseUrl = baseUrl;
    this.queryParams = {};
  
    this.addQueryParam = function(key, value) {
      this.queryParams[key] = value;
    };
  
    this.toString = function() {

        const hasQueryParams = Object.keys(this.queryParams).length > 0;

        // Ensure there is a slash at the end of the baseUrl
        const baseWithSlash = this.baseUrl.endsWith('/') > 0 || hasQueryParams ? this.baseUrl : `${this.baseUrl}/`;
        
        const queryStrings = Object.entries(this.queryParams).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        return `${baseWithSlash}${queryStrings.length > 0 ? '?' + queryStrings.join('&') : ''}`;
      };
  }

  function stripTimeFromDate(date){
    return date.hour(0).minute(0).second(0).millisecond(0);
  }

  function formatAPIDate(date, reverse=false) {

    date = date.utc();
    if(reverse){
      return date.format('DD-MM-YYYY');
    }
    return date.format('YYYY-MM-DD')
  }

export {formatDollars, formatAPIDate, appendQueryParamsToRelativeUrl, URLBuilder, formatPercent, stripTimeFromDate};