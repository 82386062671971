import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./YBRevenueWidget.css";
import dayjs from "dayjs";
import YBDatePicker from "../YBDatePicker";
import apiService from "../../services/apiService";
import { URLBuilder, formatAPIDate } from "../../scenes/global/utils";
import { WIDGET_META } from "../../constants";

function YBRevenueWidget({ revenue, onDateChanged }) {
  const [startDate, setStartDate] = useState(dayjs().startOf("month"));
  const [endDate, setEndDate] = useState(dayjs());

  useEffect(() => {
    fetchSalesReport(startDate, endDate);
  }, [startDate, endDate]);

  const fetchSalesReport = async (startDate, endDate) => {
      let url = new URLBuilder(WIDGET_META.report_sales_by_product.revenue_widget.url);
      url.addQueryParam("date_invoiced_range_after", formatAPIDate(startDate));
      url.addQueryParam("date_invoiced_range_before", formatAPIDate(endDate));
      onDateChanged(url.toString());
  };




  return (
    <div style={{ width: "80%", padding: "1rem 1.5rem" }}>
      <div>
        <h3
          style={{
            textAlign: "center",
            fontSize: "1.7rem",
          }}
        >
          Revenue
        </h3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <YBDatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
          </Grid>
          <Grid item xs={6}>
            <YBDatePicker label="End Date" value={endDate} onChange={(newValue) => setEndDate(newValue)} />
          </Grid>
        </Grid>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      <p style={{ margin: "1.5rem 0 0", textAlign: "center", fontSize: "3rem" }}>${revenue} </p>
    </div>
  );
}

export default YBRevenueWidget;
