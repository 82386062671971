import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useAuth } from "../../contexts/AuthContext";
import YBAverageSalesDeltaWidget from "../../components/widgets/YBAverageSalesDeltaWidget";
import apiService from "../../services/apiService";
import { WIDGET_META } from "../../constants";
import { useState } from "react";
import YBRevenueWidget from "../../components/widgets/YBRevenueWidget";
import { Grid } from "@mui/material";

const Dashboard = () => {
  const { businessData } = useAuth();
  const [salesDeltaData, setSalesDeltaData] = useState([]);
  const [revenueWidgetData, setRevenueWidgetData] = useState(0);
  const { authToken } = useAuth();

  useEffect(() => {
    document.title = "Yotabase: Dashboard!";
    getAverageSalesWidgetData();
    getRevenueWidgetData(WIDGET_META.report_sales_by_product.revenue_widget.url);
  }, []);

  async function getAverageSalesWidgetData() {
    const response = await apiService.get(WIDGET_META.report_product_sales_over_time.average_sales_delta.url, authToken);
    if (response !== undefined) {
      setSalesDeltaData(response);
    }
  }

  async function getRevenueWidgetData(url) {
    const response = await apiService.get(url, authToken);
    console.log(response)
    if (response !== undefined) {
      setRevenueWidgetData(response.total_revenue);
    }
  }

  return (
    <>
      <Box m="16px">
        <Header title="Yotabase Dashboard" subtitle={`Welcome to ${businessData?.business_name}'s dashboard, ${businessData?.username}!`} />{" "}
      </Box>

      {/* Widgets */}
      <Grid container spacing={2}>
        <Grid item md={6}>
          {salesDeltaData && salesDeltaData.length > 0 && <YBAverageSalesDeltaWidget objArray={salesDeltaData} />}
        </Grid>
        <Grid item md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {revenueWidgetData && <YBRevenueWidget revenue={revenueWidgetData} onDateChanged={getRevenueWidgetData} />}
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
