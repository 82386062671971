import { DatePicker } from "@mui/x-date-pickers";

function YBDatePicker({label, value, onChange, fullWidth, ...rest}){

    return (<DatePicker
                label={label}
                value={value}
                onChange={onChange}
                format="DD/MM/YYYY"
                className={fullWidth ? "fullwidth-datepicker" : ""}
                {...rest}
            />)

}

export default YBDatePicker;