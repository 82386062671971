import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import YBMenuItem  from "./global_components/YBMenuItem";
import { useAuth } from '../../contexts/AuthContext';
import {REPORT_META} from "../../constants";
import TableChartIcon from "@mui/icons-material/TableChart";


const ProSidebar = ({height}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const { businessData } = useAuth();

  const menuItems = businessData?.reports?.map((report) => {
    return (
      <YBMenuItem
        key={report.app_name}
        icon={REPORT_META[report.app_name].icon || <TableChartIcon />}
        name={report.name}
        mouseOverDescription={report.description}
        selected={selected}
        setSelected={setSelected}
        link={<Link to={REPORT_META[report.app_name].url} />}
      />
    );
  });

  return (
    <Box 
      style={{ 
        background: colors.primary[400],
        height: height,
      }}
    >
      <Sidebar
      style={{ 
        background: colors.primary[400],
      }}
      
      collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                
                <img
                  alt="Yotabase by Yotabyte"
                  width="132px"
                  height="32px"
                  src={`../../assets/yotabase-logo.png`}
                  style={{ cursor: "pointer" }}
                />
              
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>


          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
          <MenuItem  icon={<HomeOutlinedIcon />} component={<Link to="/" />} selected={selected}  setSelected={setSelected}> Dashboard</MenuItem>

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Reports
            </Typography>

          {menuItems}

          {/* <MenuItem  icon={<TableChartIcon />} component={<Link to="/sales" />} selected={selected}  setSelected={setSelected}>Sales By Product</MenuItem>
          <MenuItem  icon={<CategoryIcon />} component={<Link to="/product-sales-over-time" />} selected={selected}  setSelected={setSelected}>Product Sales Over Time</MenuItem> */}
           
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default ProSidebar;