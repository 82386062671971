import React from "react";
import "./index.css"; // Import your CSS file for styling
import Loading from "./Loading";

const ProductSalesOverTimeCustomTable = ({ data, loading, onSKUClick }) => {
  return (
    <div className="custom-table-container">
      <table className="custom-table">
        <thead style={{position:"sticky",top:"-1"}}>
          <tr>
            <th>Rank</th>
            <th>SKU</th>
            <th>Sold</th>
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((row, index) => (
              <tr key={index}>
                <td>{row.rank}</td>
                <td><a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default anchor link behavior
                        onSKUClick(row.sku);
                      }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                    {row.sku}</a></td>
                <td>{row.sold}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{loading ? <Loading /> : "No record found."}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductSalesOverTimeCustomTable;
