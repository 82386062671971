import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { formatDollars, formatPercent } from "../global/utils";

export default function BasicPie( { rows, abs, MAX = 10 } ) {

    const sortedRows = rows.sort((a, b) => b[1] - a[1]);

    const limitedRows = sortedRows.slice(0, MAX);
    const otherRows = sortedRows.slice(MAX);


    const otherSum = otherRows.reduce((acc, row) => acc + parseFloat(row[1]), 0);

    if (otherRows.length > 0) {
        limitedRows.push(["Other", otherSum]);
    }

    const transformedRows = limitedRows.map((row, index) => ({
        id: index,
        value: parseFloat(row[1]),
        label: row[0],
      }));

    function valueFormatter(value) {
        return abs ? formatDollars(value.data) : formatPercent(value.data);
    }

    return (
        <PieChart
        series={[
            {
            data: transformedRows,
            valueFormatter
            },
        ]}
        width={1200}
        height={600}
        />
    );
}