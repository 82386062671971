import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";


export default function YBSelect({ title, options, onChange, value }){
    

    const renderedItems = options.map((option) => {
        return (
            <MenuItem value={option.id}>{option.label}</MenuItem>
        )
    })

    function handleChange(event){
        let selectedOption = options.find((option) => option.id === event.target.value)
        onChange(selectedOption)
    }

    return (
        <FormControl fullWidth sx={{marginTop: "20px"}}>
            <InputLabel>{title}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
            >
                { renderedItems }
            </Select>
        </FormControl>
    )
}